import React from 'react';
import { graphql } from "gatsby"
import Layout from "../page-components/layout"
import SEO from "../page-components/seo"
import RcCard from "../components/card/rcCard"
import RcDefaultPageTeaser from "../page-components/teaser/rcDefaultPageTeaser"

const ImmosServicePage = ( { data } ) => {

  return <Layout>

    <SEO title={"Immobilien Services"} />

    <RcDefaultPageTeaser>
      <h2>Immobilienverwaltung und Immobilien Services</h2>

      <div dangerouslySetInnerHTML={
        {
          __html: data.wp.texteFRSektionen.introimmoservices
        }
      } />

    </RcDefaultPageTeaser>

    <div className="container pt-3 bg-white h-100">

      <div className="row pb-5">
        { data.allWpPage.nodes.map(
          n =>  <RcCard to={"/" + n.slug}
                        caption={n.title}
                        className={"mb-4"}
                        description={n.acf.description}
                        img={n.acf?.thumbnail?.localFile?.childImageSharp?.gatsbyImageData} />
        )}
      </div>

    </div>

  </Layout>

};

export default ImmosServicePage;

export const query = graphql`
  query ImmoServicePageQuery {
   
     wp {
      texteFRSektionen {
          acf {
            introimmoservices
          }
      }
    }
   
    allWpPage(filter: {acf: {art: {in: [ "immo" ] }}}) {
      nodes {
        acf {
          art
          description
          thumbnail {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        slug
        title
      }
    }
    
    
  }
    
    
`
